import React, { useState } from "react";
import Modal from "../Modal";
function ContinueTraining({setOpenModalTraining}) {
    const text = `<div style="text-align:justify;font-size:16px"><p>Ya tienes a tu 
    disposición las actividades de formación continua 
    para que refuerces tus conocimientos en el o los programas de Kurios que 
    impartes. En el panel derecho encontrarás la formación correspondiente a 
    todos los programas Kurios del 1er y 2do lapso </p> <br/><br/>
      <p> Elije las formaciones correspondientes solo a los programas que impartes.</p><br/><br/>
      <p>Estas Formaciones estarán a tu alcance a lo largo del año escolar para que siempre 
      puedas volver a revisar y reforzar tus conocimientos.</p>
      </div>`
    return (
        <div>

            <Modal
            title={'Formación Continua'}
            modalOpen={setOpenModalTraining}
            type={"info"}
        >
            <div dangerouslySetInnerHTML={{ __html:  text}} />
        </Modal>
        </div>
    );
};

export default ContinueTraining;