/**
 * Genera una presentación en PowerPoint utilizando `pptxgenjs` para una institución.
 *
 * Esta función construye una presentación de diapositivas basada en datos e imágenes proporcionadas,
 * incluyendo información sobre la institución, habilidades, asuntos pendientes, y una serie
 * de secciones predefinidas como Beneficios Kurios y Alianzas.
 *
 * @param {Array} images - Arreglo de objetos que contiene las imágenes (en formato Base64) y sus nombres.
 * @param {Object} institution - Objeto que representa la institución para la que se genera el PPT.
 * @param {Array} asuntosEmergentes - Arreglo de puntos pendientes a tratar, que serán agregados en la presentación.
 * @param {Array} optionalSlides - Arreglo que contiene información sobre las diapositivas opcionales a incluir.
 * @param {Array} teacherData - Datos de los profesores, incluyendo sus habilidades y seguimiento en capacitaciones.
 * @param {string} linkDashboard - Enlace a la información de la alianza, que se agregará en la diapositiva de Alianzas.
 * @param {function} setLoading - Función para activar o desactivar el estado de carga durante la generación del PPT.
 */
import React from 'react'
import PptxGenJS from 'pptxgenjs';
import { skills , yearStageImages, skillsTekman,
  imagesForVerticalGrowth
} from './constantInstitution';
/**
 * Función auxiliar que obtiene el valor de una diapositiva opcional por nombre
 * Se debe utilizar para las laminas opcionales (proyeccion academica)
 * */
const getValueByName = (optionalSlides, name) => {
  const option = optionalSlides?.find(option => option.name === name);
  return option ? option.valor : false;
}

export const GeneratePPT = ( images, institution, asuntosEmergentes, optionalSlides,
   teacherData, withTekman, thinkoAi, verticalGrowth,linkDashboard, setLoading ) => {
    let pptx = new PptxGenJS();
    // DIAPOSITIVA PORTADA
    const slidePortada = pptx.addSlide();
    slidePortada.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/portadalapsoII.png', x: 0, y: 0, w: '100%', h: '100%' });
    //Agregar logo institucion    
    const url = institution?.logo;
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const relativePath = pathname.substring(1);
    //NUEVA URL PARA LOGO
    const newUrl = `https://kuriosedu.s3.us-east-2.amazonaws.com/${relativePath}`
    slidePortada.addImage({ path: newUrl, x: 3.55, y: 3.1,  w: 1.4,  h: 1.4 });
    // aqui estaba y 0.5 e w y h en 1 

    //AGREGAR IMAGENES EN PORTADA
    const positions = [ 
      { x: 7.89, y: 0, w: 2.165, h: 2.83 }, // Imagen 1 (Esquina superior derecha)
      { x: 5.72, y: 0, w: 2.165, h: 2.83 }, // Imagen 2 (Al lado de Imagen 1)
      { x: 5.72, y: 2.81, w: 4.33, h: 2.82 }  // Imagen 3 (Mitad inferior sin chocar con el logo)
    ];
    images.forEach((image, index) => {
      if (image.image) {
        slidePortada.addImage({
        data: image.image,  // Base64 image
        x: positions[index].x,
        y: positions[index].y,
        w: positions[index].w,
        h: positions[index].h,
        });
      }
    });

    // DIAPOSITIVA DE TEMAS A TRATAR
    const slideTemas = pptx.addSlide();
    if (thinkoAi) {
      slideTemas.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/puntotekman2.png', x: 0, y: 0, w: '100%', h: '100%' });
    }
    else{
      slideTemas.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/puntosintekman3.png', x: 0, y: 0, w: '100%', h: '100%' });
    }

    //DIAPOSITIVA PORTADA AVANCES DEL LAPSO Y PROYECCION
    const slideAvances = pptx.addSlide();
    slideAvances.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/avances.png', x: 0, y: 0, w: '100%', h: '100%' });

    //DIAPOSITIVA DE PROYECCION ACADEMICA
    const isYearSelected = (optionalSlides, year) => getValueByName(optionalSlides, year);
    // Función para agregar las diapositivas correspondientes a cada año y etapa
    const addSlidesForYearAndStage = (year, stage) => {
      const images = yearStageImages[year]?.[stage] || [];
      images.forEach((imagePath) => {
        const slide = pptx.addSlide();
        slide.addImage({ path: imagePath, x: 0, y: 0, w: '100%', h: '100%' });
      });
    };
    const uniqueStages1 = new Set();
    const stageOrder1 = ['Preescolar', 'Primaria', 'Bachillerato'];// Orden de los stages
    teacherData?.forEach((teacher) => {
      const normalizedStages1 = teacher?.stages?.map(stage => {
        if (stage === "Primaria Menor" || stage === "Primaria Mayor") {
          return "Primaria";
        }
        return stage;
      });
      normalizedStages1.forEach(stage => uniqueStages1.add(stage));
    });
    // Proyeccion academica por etapa y año
    ['Año 1', 'Año 2', 'Año 3','Año 4+', 'Digital Skills I', 
    'Digital Skills II','Marketplace','Digital Skills I - Marketplace', 
    , 'Digital Skills I y II','Digital Skills I y II - Marketplace', 
       'Digital Skills II - Marketplace'].forEach(year => {
      if (isYearSelected(optionalSlides, year)) {
        stageOrder1.forEach(stage => {
          if (uniqueStages1.has(stage)) {
            addSlidesForYearAndStage(year, stage);
          }
        });
      }
    });

    //DIAPOSITIVA SI TIENEN TEKMAN 
    if (withTekman) {
      const slideTekman = pptx.addSlide();
      slideTekman.addImage({ path: 
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/avancesII.png',
         x: 0, y: 0, w: '100%', h: '100%' });
       //DIAPOSITIVA CRITERIOS DE TEKMAN
      let slideAnalisisTekman = pptx.addSlide();
      const addSkillsToSlideTekman = (slide) => {
      slide.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/fondoAzulTekman.png', x: 0, y: 0, w: '100%', h: '100%' });
      slide.addText("Avances II Lapso", {
          x: 3.5,
          y: 0.35,
          fontSize: 22,
          color: 'FFFFFF',
          bold: true,
          fontFace:'Soho Gothic'
      });
      slide.addText("Tekman Primaria", {
          x: 3.5,
          y: 0.75,
          fontSize: 20,
          color: 'FFFFFF',
          fontFace:'Soho Gothic'
      });
      slideAnalisisTekman.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.4,
        y: 1.1,  // Un poco antes del texto del nombre para que se vea bien el fondo
        w: 4.2,   // Ancho del rectángulo (igual que el texto)
        h: 0.4,   // Altura del rectángulo
        fill: { color: '31006F' }, 
        radius: 8,  // Bordes redondeados
      });
      slide.addText("Criterios de Tekman", {
          x: 0.5,
          y: 1.3,
          w: 4.2,
          fontSize: 12,
          color: 'FFFFFF',
          align: 'center',
          fontFace:'Open Sans'
      });

    // Añadir habilidades
    skillsTekman?.forEach((skill, index) => {
      let yPosition = 1.6 + index * 0.42; // Espaciado entre habilidades
      // Fondo para cada habilidad
      slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
        x: 0.4,
        y: yPosition,
        w: 4.2, // Anchura de la columna de habilidades
        h: 0.35, // Altura de cada fila de habilidad
        fill: { color: 'FFFFFF' },// Fondo en blanco
        radius: 8, // Bordes redondeados
      });

      // Texto de la habilidad
      slide.addText(skill, {
        x: 0.45, // Un poco dentro del rectángulo
        y: yPosition + 0.2, // Centrado verticalmente
        fontSize: 10,
        w: 4.2,
        align:'center',
        color: '898989', // Texto en gris
        fontFace:'Open Sans'
      });
    });
    };

    // Llamar la función para agregar las habilidades a la primera diapositiva
    addSkillsToSlideTekman(slideAnalisisTekman);

    let columnCount = 0;
    for (let i = 0; i < 3; i++) {
      let columnIndex = columnCount % 2; // Mantiene siempre dos columnas por diapositiva
      let xPosition = 4.8 + columnIndex * 1.7; // Espaciado entre columnas

      slideAnalisisTekman.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
          x: xPosition, y: 1.1, w: 1.5, h: 0.4, fill: { color: '31006F' }, radius: 8
      });

      skillsTekman?.forEach((_, skillIndex) => {
          let yPosition = 1.6 + skillIndex * 0.42;
          slideAnalisisTekman.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
              x: xPosition, y: yPosition, w: 1.5, h: 0.35, fill: { color: 'FFFFFF' }, radius: 5
          });
      });

      columnCount++;
    }
  }

    //DIAPOSITIVA PORTADA SEGUIMIENTO DOCENTES
    const slideSeguimiento = pptx.addSlide();
    slideSeguimiento.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/seguimientodocentes.png', x: 0, y: 0, w: '100%', h: '100%' });

    // DIAPOSITIVA ANÁLISIS DE ACOMPAÑAMIENTO
  let slideAnalisis = pptx.addSlide();
  const addSkillsToSlide = (slide) => {
  slide.addText('Seguimiento docentes', {
    x: 2.8,
    y: 0.45,
    fontSize: 20,
    bold: true,
    color: 'ed6b23',
    fontFace:'Open Sans'
  });

  slide.addText('Habilidades / Programas', {
    x: 0.5,
    y: 0.9,
    w: 4.2,
    fontSize: 12,
    bold: true,
    color: '330072',
    align: 'center',
    fontFace:'Open Sans'
  });
  

  // Añadir habilidades
  skills?.forEach((skill, index) => {
    let yPosition = 1.3 + index * 0.40; // Espaciado entre habilidades
    let color;
    if (index < 4) {
      color = '6859A6'; // Primeros 4 items
    } else if (index >= 4 && index < 8) {
      color = '330072'; // Próximos 8 items
    } else {
      color = 'C1BBED'; // Últimos 2 items
    }
    // Fondo para cada habilidad
    slide.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
      x: 0.4,
      y: yPosition,
      w: 4.4, // Anchura de la columna de habilidades
      h: 0.35, // Altura de cada fila de habilidad
      fill: { color: color },
      radius: 8, // Bordes redondeados
    });

    // Texto de la habilidad
    slide.addText(skill, {
      x: 0.45, // Un poco dentro del rectángulo
      y: yPosition + 0.15, // Centrado verticalmente
      fontSize: 10,
      w: 4.2,
      align:'center',
      color: 'FFFFFF', // Texto en blanco 
      fontFace:'Open Sans'
    });
  });
  };

  // Llamar la función para agregar las habilidades a la primera diapositiva
  addSkillsToSlide(slideAnalisis);

  const stageOrder = ['Preescolar', 'Primaria', 'Bachillerato'];// Orden de los stages
  let teacherActive = new Set();
  teacherActive = teacherData?.filter((teacher) => 
    teacher?.info?.is_active === true && teacher?.stages?.length > 0);

  // Eliminar duplicados en los stages normalizados y ordenar los stages
  const uniqueTeacherStagesSet = new Set(); // Guarda "stage + teacher" únicos
  let usedStages = new Set(); // Guarda los stages usados en la diapositiva actual
  
  teacherActive?.forEach((teacher) => {
    const specificColorForRow14 = (teacher?.encuestasListas === teacher?.encuestas?.length) ? 'A2D394' 
      : (teacher?.encuestasListas > 0 ? 'FFD394' : 'EFEFEF'); 
  
    const normalizedStages = teacher?.stages?.map(stage => 
      (stage === "Primaria Menor" || stage === "Primaria Mayor") ? "Primaria" : stage
    );
  
    const uniqueTeacherStages = [...new Set(normalizedStages)].sort((a, b) => 
      stageOrder.indexOf(a) - stageOrder.indexOf(b)
    );
  
    uniqueTeacherStages?.forEach((stage) => {
      const teacherStageKey = `${stage}_${teacher?.info?.first_name}_${teacher?.info?.last_name}`;
  
      //agregar si este "stage + docente" no ha sido agregado antes
      if (!uniqueTeacherStagesSet.has(teacherStageKey)) {
        uniqueTeacherStagesSet.add(teacherStageKey);
  
        let columnIndex = usedStages.size;
        let xPosition = 5 + columnIndex * 1.7;
  
        // Agregar rectángulo y texto
        slideAnalisis.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
          x: xPosition, y: 0.7, w: 1.5, h: 0.5, fill: { color: '330072' }, radius: 8,
        });  
        slideAnalisis.addText(stage, {
          x: xPosition, y: 0.84, fontSize: 10, bold: true, color: 'FFFFFF',
           w: 1.5, align: 'center', fontFace: 'Open Sans'
        });  
        slideAnalisis.addText(`${teacher?.info?.first_name} ${teacher?.info?.last_name}`, {
          x: xPosition, y: 1.04, fontSize: 10, bold: true, color: 'FFFFFF', w: 1.5, 
          align: 'center', fontFace: 'Open Sans'
        });
  
        skills?.forEach((_, skillIndex) => {
          let yPosition = 1.3 + skillIndex * 0.40;
          let fillColor = (skillIndex === 12) ? specificColorForRow14 : 'EFEFEF';
  
          slideAnalisis.addShape(pptx.shapes.ROUNDED_RECTANGLE, {
            x: xPosition, y: yPosition, w: 1.5, h: 0.35, fill: { color: fillColor }, radius: 5,
          });
        });
  
        usedStages.add(stage); // Agregar el stage a los usados en esta diapositiva
  
        // Si llegamos al límite de columnas, creamos nueva diapositiva
        if (usedStages.size === 2) {
          slideAnalisis = pptx.addSlide();
          addSkillsToSlide(slideAnalisis);
          usedStages.clear(); // Reiniciar las columnas usadas en la nueva diapositiva
        }
      }
    });
  });
  
    //Si tiene ThinkoAi
    if (thinkoAi) {
      const slideThinkoAi = pptx.addSlide();
      slideThinkoAi.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/ThinkoAi.png', x: 0, y: 0, w: '100%', h: '100%' });
      slideThinkoAi.addText("Cantidad de usuarios docentes creados y activos", {
        x: 1,
        y: 1.8,
        w: 4,
        fontSize: 18,
        align: 'center',
        color: 'FFFFFF',
        bold: true,
        fontFace:'Soho Gothic'
      })
      slideThinkoAi.addText("Total", {
        x: 7,
        y: 2.8,
        fontSize: 25,
        color: 'FFFFFF',
        bold: true,
        fontFace:'Soho Gothic'
      })
      slideThinkoAi.addText("durante el período 24-25", {
        x: 6.6,
        y: 3.8,
        fontSize: 10,
        color: 'FFFFFF',
        bold: true,
        fontFace:'Soho Gothic'
      })

    }
    //DIAPOSITIVA PORTADA DE PUNTOS PENDIENTES
    const slidePtosPendientes = pptx.addSlide();
    
    slidePtosPendientes.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/ptosPendientes.png', x: 0, y: 0, w: '100%', h: '100%' });
    
    //DIAPOSITIVAS PUNTOS PENDIENTES
    if(asuntosEmergentes?.length > 0 ){
      const slidePtosPendientes2 = pptx.addSlide();
      slidePtosPendientes2.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/puntospendientes.png', x: 0, y: 0, w: '100%', h: '100%' });
      slidePtosPendientes2.addText('Puntos pendientes', {
        x: 0.5,
        y: 0.8,
        fontSize: 20,
        bold: true,
        color: 'FFFFFF',
        fontFace:'Open Sans',

      });
      const leftPoints = asuntosEmergentes.slice(0, 3);
      const rightPoints = asuntosEmergentes.slice(3, 6);
      const leftColumnX = 0.4;
      const rightColumnX = 5;
      let yPosition = 1.1;

      const addPointsToColumn = (points, columnX, startIndex) => {
        points.forEach((point, index) => {
            slidePtosPendientes2.addText(point, {
                x: columnX,
                y: yPosition + index * 0.5, 
                fontSize: 16,
                color: "FFFFFF",
                bullet: true, 
                w: 6,
                h: 0.5,
                fontFace: 'Open Sans'
            });
        });
    
        yPosition += 1.3 * points.length; 
    };
    
      addPointsToColumn(leftPoints, leftColumnX, 0);

      // Reiniciar la posición y añadir puntos a la columna derecha
      yPosition = 1.5;
      addPointsToColumn(rightPoints, rightColumnX, 3);
      
    }
    //PORTADA INNOVACIONES
    const slideInnovaciones = pptx.addSlide();
    
    slideInnovaciones.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/proximas.png', x: 0, y: 0, w: '100%', h: '100%' });

    if(verticalGrowth){
      const slideInnovaciones1 = pptx.addSlide();
      slideInnovaciones1.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/66.png', x: 0, y: 0, w: '100%', h: '100%' });
  
      const slideInnovaciones2 = pptx.addSlide();
      slideInnovaciones2.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/67.png', x: 0, y: 0, w: '100%', h: '100%' });
    } 

    const slideInnovaciones3 = pptx.addSlide();
    slideInnovaciones3.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/68.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideInnovaciones4 = pptx.addSlide();
    slideInnovaciones4.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/69.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideInnovaciones5 = pptx.addSlide();
    slideInnovaciones5.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/70.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideInnovaciones6 = pptx.addSlide();
    slideInnovaciones6.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/71.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideInnovaciones7 = pptx.addSlide();
    slideInnovaciones7.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/72.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideInnovaciones8 = pptx.addSlide();
    slideInnovaciones8.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/73.png', x: 0, y: 0, w: '100%', h: '100%' });

    //PORTADA PROPUESTA ECONOMICA
    const slidePropuesta = pptx.addSlide(); 
    slidePropuesta.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/propuesta.png', x: 0, y: 0, w: '100%', h: '100%' });
   
    const slidePropuesta1 = pptx.addSlide();
    slidePropuesta1.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/propuestaeconomica.png', x: 0, y: 0, w: '100%', h: '100%' });
    slidePropuesta1.addImage(
      { path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/mas.PNG', 
      x: 3.3, y: 3, w: 0.5, h: 0.5 },
    )
    slidePropuesta1.addText("5.9$",{
      x:3, y: 2.3, w:1.2, h:0.5, fontSize:18,
      align: 'center',
      color: '31006F',
      bold: true,
      fontFace: 'Soho Gothic'
    })
    slidePropuesta1.addText("mensuales por estudiantes",{
      x:2.2, y: 2.5, w:3.5, h:0.5, fontSize:14,
      color: '31006F',
      fontFace: 'Soho Gothic'
    })
    slidePropuesta1.addText("16.5$ ",{
      x:3, y: 3.6, w:1.2, h:0.5, fontSize:18,
      align: 'center',
      color: '31006F',
      bold: true,
      fontFace: 'Soho Gothic'
    })
    slidePropuesta1.addText("mensuales por hora académica",{
      x:2.2, y: 3.8, w:3.5, h:0.5, fontSize:14,
      color: '31006F',
      fontFace: 'Soho Gothic'
    })
    slidePropuesta1.addText("5.9$ ",{
      x:5.8, y: 2.8, w:1.2, h:0.5, fontSize:18,
      align: 'center',
      color: '31006F',
      bold: true,
      fontFace: 'Soho Gothic'
    })
    slidePropuesta1.addText("anuales por estudiantes",{
      x:5.4, y: 3, w:3.5, h:0.5, fontSize:14,
      color: '31006F',
      fontFace: 'Soho Gothic'
    })


    //PORTADA TORNEO
    const slideTorneo = pptx.addSlide();
    slideTorneo.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/eventomas.png', x: 0, y: 0, w: '100%', h: '100%' });
    
    const slideTorneo1 = pptx.addSlide();
    slideTorneo1.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/77.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideTorneo2 = pptx.addSlide();
    slideTorneo2.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/Clasificados.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideTorneo3 = pptx.addSlide();
    slideTorneo3.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/79.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideTorneo4 = pptx.addSlide();
    slideTorneo4.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/80.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideTorneo5 = pptx.addSlide();
    slideTorneo5.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/81.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideTorneo6 = pptx.addSlide();
    slideTorneo6.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/82.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideTorneo7 = pptx.addSlide();
    slideTorneo7.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/83.png', x: 0, y: 0, w: '100%', h: '100%' });
    
    const slideTorneo8 = pptx.addSlide();
    slideTorneo8.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/84.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideTorneo9 = pptx.addSlide();
    slideTorneo9.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/85.png', x: 0, y: 0, w: '100%', h: '100%' });

    const slideTorneo10 = pptx.addSlide();
    slideTorneo10.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/86.png', x: 0, y: 0, w: '100%', h: '100%' });
    
    //DIAPOSITIVA GRACIAS
    const slideFinal = pptx.addSlide();
    slideFinal.addImage({ path: 'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/87.png', x: 0, y: 0, w: '100%', h: '100%' });
    
    // Descargar la presentación
    pptx.writeFile({ fileName: `Presentación-${institution?.name}` });
    setLoading(false)
}
