/**
 * Componente InstitutionResumePPT
 *
 * Este componente permite la creación de una presentación de PowerPoint para una institución.
 * Proporciona una interfaz para cargar imágenes, agregar notas, seleccionar diapositivas opcionales para el año kurios,
 * y añadir un enlace de aprovechamiento del poweBI. Una vez configurado, el componente genera el archivo PPT.
 *
 * @param {Object} props - Propiedades del componente
 * @param {Object} props.institution - Datos de la institución, incluyendo `teachers`
 * @param {boolean} props.pptModalOpen - Estado que indica si el modal de PPT está abierto
 * @param {function} props.setPptModalOpen - Función para controlar la apertura/cierre del modal de PPT
 * @returns {React.Component} Componente que permite la configuración y generación de una presentación PPT
 */

import React, {useState, useEffect, useRef} from 'react'
import {
  Button,
  Grid,
  Stack,
  Checkbox, FormControlLabel, FormGroup,
} from '@mui/material';
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { CAPACITACION_ENDPOINT, USER_FORM_ENDPOINT } from '../../../utils/constants';
import { get } from '../../../utils/http';
import Modal from '../../Modal'
import styles from './InstitutionResumePPT.module.css'
import { GeneratePPT } from './GeneratePPT';
import LoadingComponent from '../../LoadingComponent';

function InstitutionResumePPT({institution, pptModalOpen, setPptModalOpen}) {
  const [images, setImages] = useState([
    { image: null, name: null },
    { image: null, name: null },
    { image: null, name: null },
  ]);
  const [asuntosEmergentes, setAsuntosEmergentes] = useState([]);
  const [openAsuntosEmergentesModal, setOpenAsuntosEmergentesModal] = useState(false)
  const [optionalSlidesModal, setOptionalSlidesModal] = useState(false)
  const [teacherData, setTeacherData] = useState([]);
  const [tekmanModal, setTekmanModal] = useState(false)
  const [thinkoAiModal, setThinkoAiModal] = useState(false)
  const [withTekman, setWithTekman] = useState(false)
  const [thinkoAi, setThinkoAi] = useState(false)
  const [verticalGrowth, setVerticalGrowth] = useState(false)
  const [linkDashboard, setLinkDashboard] = useState("")
  const [openLinkDashboard, setOpenLinkDashboard] = useState(false)
  const [optionalSlides, setOptionalSlides] = useState([
    { id: 1, name: "Año 1",
    valor: false},
    { id: 2, name: "Año 2",
    valor: false},
    { id: 3, name: "Año 3",
    valor: false},
    { id: 4, name: "Año 4+",
    valor: false},
    { id: 5, name: "Digital Skills I",
    valor: false},
    { id: 6, name: "Digital Skills II",
    valor: false},
    { id: 7, name: "Marketplace",
    valor: false},
    { id: 8, name: "Digital Skills I y II",
    valor: false},
    { id: 9, name: "Digital Skills I - Marketplace",
    valor: false},
    { id: 10, name: "Digital Skills I y II - Marketplace",
    valor: false},
    { id: 11, name: "Digital Skills II - Marketplace",
    valor: false},
  ])
  const [optionTekman, setOptionTekman] = useState([
    { id: 1, name: "Tekman", valor: false},
    { id: 2, name: "Sin Tekman", valor: false},
  ])
  const [optionThinkoAi, setOptionThinkoAi] = useState([
    { id: 1, name: "ThinkoAi", valor: false},
    { id: 2, name: "Sin ThinkoAi", valor: false},
    { id: 3, name: "Crecimiento Vertical", valor: false},
    { id: 4, name: "Sin Crecimiento Vertical", valor: false},
  ])
  const [loading, setLoading] = useState(false);

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(950))

  // Referencia para los inputs de carga de imágenes
  const inputRefs = useRef([]);

  /**
   * useEffect - Carga de datos de los profesores
   *
   * Realiza una solicitud a la API para obtener información sobre las capacitaciones y encuestas de cada profesor
   * de la institución, y almacena los datos en el estado `teacherData`.
   */
  useEffect(() => {
    const fetchAllData = async () => {
    const fetchedTeacherData = await Promise.all(
      institution?.teachers?.map(async (teacher) => {
        const capacitacionesResponse = await get({
          url: `${CAPACITACION_ENDPOINT}user/${teacher.info.id}/`,
        });
        const capacitacionesFiltered = capacitacionesResponse?.data?.filter((item) => item?.term?.name === "I")
        const encuestasResponse = await get({
          url: `${USER_FORM_ENDPOINT}user/${teacher?.info?.id}/`,
        });
        const capacitacionesConTareas = capacitacionesResponse?.error
        ? 0
        : capacitacionesFiltered?.reduce((count, capacitacion) => {
            return (capacitacion?.training_task_user > 0 || capacitacion?.quiz_user) ? count + 1 : count;
          }, 0);
        const encuestasListas = encuestasResponse.error
        ? 0
        : encuestasResponse?.data?.reduce((count, form) => {
            return form.completed ? count + 1 : count;
          }, 0);
        return {
          ...teacher,
          capacitaciones: capacitacionesResponse.error ? null : capacitacionesFiltered,
          encuestas: encuestasResponse.error ? null : encuestasResponse.data,
          capacitacionesConTareas,
          encuestasListas
        };
      }) || []
    );
    setTeacherData(fetchedTeacherData);
    };
  fetchAllData();
  }, [institution?.teachers]);

  /**
   * Carga una imagen desde una URL en formato Base64.
   * @param {string} url - La URL de la imagen a cargar.
   */
  const loadImageAsBase64 = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error loading image:', error);
      return null;
    }
  };

  /**
   * handleAddNote - Agrega un nuevo punto en la lista de puntons pendientes.
   */
  const handleAddNote = () => {
    if (asuntosEmergentes.length < 6) {
      setAsuntosEmergentes([...asuntosEmergentes, ""]); 
    } else {
      alert("No puedes agregar más de 6 puntos."); 
    }
  };
  const handleTekmanChange = (selectedIndex) => {
    const updatedOptions = optionTekman.map((option, index) => ({
      ...option,
      valor: index === selectedIndex, 
    }));
  
    setOptionTekman(updatedOptions);
    setWithTekman(updatedOptions[selectedIndex].id === 1); 
  };

  const handleConfirmThinkoAi = () => {
    setThinkoAiModal(false);
    setTekmanModal(true)

  };
  const handleThinkoAiChange = (selectedIndex) => {
    const updatedOptions = optionThinkoAi.map((option, index) => {
      if (index === selectedIndex) {
        return { ...option, valor: !option.valor }; 
      } 
      if ((option.id === 1 && selectedIndex === 1) || (option.id === 2 && selectedIndex === 0)) {
        return { ...option, valor: false };
      }
      if ((option.id === 3 && selectedIndex === 3) || (option.id === 4 && selectedIndex === 2)) {
        return { ...option, valor: false };
      }
      return option;
    });
  
    setOptionThinkoAi(updatedOptions);
    setThinkoAi(updatedOptions.find(option => option.id === 1)?.valor || false);
    setVerticalGrowth(updatedOptions.find(option => option.id === 3)?.valor || false);
  };
  
  

  const handleConfirmTekman = () => {
    setTekmanModal(false);
    setOpenAsuntosEmergentesModal(true)

  };
  /**
   * handleNoteChange - Actualiza el contenido de un asunto emergente.
   * @param {Object} e - Evento de cambio del input.
   * @param {number} index - Índice del asunto a modificar.
   */
  const handleNoteChange = (e, index) => {
    const newNotes = [...asuntosEmergentes];
    newNotes[index] = e.target.value;
    setAsuntosEmergentes(newNotes);
  };

  /**
   * handleDeleteNote - Elimina un asunto emergente en función del índice.
   * @param {number} index - Índice del asunto a eliminar.
   */
  const handleDeleteNote = (index) => {
    const newNotes = [...asuntosEmergentes];
    newNotes.splice(index, 1); 
    setAsuntosEmergentes(newNotes);
  };

  // Configura el número máximo de notas por columna
  const maxNotesPerColumn = asuntosEmergentes?.length > 6 ? 4 : 3;
  const columns = [];
  for(let i = 0; i < asuntosEmergentes.length; i += maxNotesPerColumn) {
      const slice = asuntosEmergentes.slice(i, i + maxNotesPerColumn);
      columns.push(slice);
  }

  /**
   * handleProgramImageButtonClick - Dispara el input para subir imágenes de un programa específico.
   * @param {number} index - Índice del input de imagen a activar.
   */
  const handleProgramImageButtonClick = (index) => {
    inputRefs.current[index].click(); // Disparar el input específico
  };

  /**
   * handleProgeamImageUpload - Carga y almacena la imagen seleccionada en el estado `images`.
   * @param {Object} e - Evento de cambio del input de archivo.
   * @param {number} index - Índice del programa al que pertenece la imagen.
   */
  const handleProgramImageUpload = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        let newImages = [...images];
        newImages[index] = { ...newImages[index], image: reader.result, name: file.name };
        setImages(newImages);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOpenNotesModal = () => {
    setPptModalOpen(false)
    setThinkoAiModal(true)
  }

  const handleOpenMoreModal = () => {
    setOpenAsuntosEmergentesModal(false)
    setOptionalSlidesModal(true)
  }

  const handleOpenLinkModal = () => {
    setOptionalSlidesModal(false)
    setOpenLinkDashboard(true)
  }

  const handleCheckboxChange = (index) => {
    const newOptional = [...optionalSlides];
    newOptional[index].valor = !newOptional[index].valor;
    setOptionalSlides(newOptional);
  }


  /**
   * handleGeneratePPT - Genera la presentación en PowerPoint con los datos configurados.
   */
  const handleGeneratePPT = async () => {
    setLoading(true); 
    try {
      await GeneratePPT(images, institution, asuntosEmergentes, optionalSlides, 
        teacherData, withTekman, thinkoAi, verticalGrowth, linkDashboard, setLoading);
      
    } catch (error) {
      console.error("Error generando el archivo:", error);
      alert("Error generando el archivo: " + error);
      setLoading(false);
    } 
  };

  return (
    <div>
      {pptModalOpen &&
      <Modal title={"Cargar imágenes"} minWidth={isDesktop ? "50%" : "90%"}
      modalOpen={setPptModalOpen}>
        <div style={{height:'100%', overflowY:'scroll', width:'100%'}}>
          {images?.map((_, index) => {
              const imageName = images[index]?.name;
              return (
                <Grid container direction={"row"} rowGap={2} columnSpacing={2} sx={{ marginBottom: '10px' }} key={index}>
                  <Grid item md={5} xs={4}>
                    {`Imagen ${index + 1}:`}
                  </Grid>
                  <Grid item md={7} xs={8} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      ref={(el) => (inputRefs.current[index] = el)} // Asigna la referencia al input específico
                      onChange={(e) => handleProgramImageUpload(e, index)}
                    />
                    <Stack direction={'row'} spacing={2}>
                      {imageName && <p style={{ width: '50%' }}>{imageName}</p>}
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleProgramImageButtonClick(index)}
                        sx={{ borderRadius: '10px', marginLeft: 'auto' }}
                      >
                        {imageName ? 'Cambiar Imagen' : 'Subir Imagen'}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              );
            })}
          <div 
          style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
          <button onClick={handleOpenNotesModal}
          className={styles.button}>Siguiente</button>
          </div>
          </div>
      </Modal>}
      {thinkoAiModal &&
      <Modal title={"Seleccionar Opción ThinkoAI y Crecimiento Vertical"} minWidth={isDesktop ? "50%" : "90%"}
        modalOpen={setThinkoAiModal}>
        <div style={{ height: '100%', overflowY: 'scroll', width: '100%' }}>
          <p style={{ margin: '5px', padding: '10px', textAlign: 'center' }}>
            Indique si la presentación incluye ThinkoAI y Crecimiento vertical.
          </p>
          <FormGroup sx={{ marginLeft: '20px' }}>
            {optionThinkoAi.map((option, index) => (
              <FormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    checked={option.valor}
                    onChange={() => handleThinkoAiChange(index)}
                    sx={{ transform: 'scale(1.5)' }} // Ajusta el tamaño del checkbox
                  />
                }
                label={option.name}
                sx={{ marginBottom: '0.5rem' }}
              />
            ))}
          </FormGroup>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <button onClick={handleConfirmThinkoAi} className={styles.button}>Aceptar</button>
          </div>
        </div>
      </Modal>
      }
      {tekmanModal &&
      <Modal title={"Seleccionar Opción Tekman"} minWidth={isDesktop ? "50%" : "90%"}
        modalOpen={setTekmanModal}>
        <div style={{ height: '100%', overflowY: 'scroll', width: '100%' }}>
          <p style={{ margin: '5px', padding: '10px', textAlign: 'center' }}>
            Indique si la presentación incluye Tekman o no.
          </p>
          <FormGroup sx={{ marginLeft: '20px' }}>
            {optionTekman.map((option, index) => (
              <FormControlLabel
                key={option.id}
                control={
                  <Checkbox
                    checked={option.valor}
                    onChange={() => handleTekmanChange(index)}
                    sx={{ transform: 'scale(1.5)' }} // Ajusta el tamaño del checkbox
                  />
                }
                label={option.name}
                sx={{ marginBottom: '0.5rem' }}
              />
            ))}
          </FormGroup>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <button onClick={handleConfirmTekman} className={styles.button}>Aceptar</button>
          </div>
        </div>
      </Modal>
}
      {openAsuntosEmergentesModal &&
      <Modal title={"Puntos Pendientes"} modalOpen={setOpenAsuntosEmergentesModal}>
        <div style={{height:'100%', overflowY:'scroll'}}>
          {asuntosEmergentes?.map((note, index) => (
            <div key={index} style={{minWidth: '650px', display: 'flex', alignItems: 'center'}}>
              <textarea 
                value={note}
                onChange={(e) => handleNoteChange(e, index)}
                placeholder="Ingrese el punto aquí..."
                rows="3"
                style={{flex: 1, marginRight: '1rem', borderRadius: '10px', 
                padding: '5px', marginTop: '5px'}}
              />
              <i class="fas fa-times-circle" onClick={() => handleDeleteNote(index)}
              style={{cursor: 'pointer',  color: '#ee3c3a'}} ></i>
            </div>
          ))}
          {asuntosEmergentes.length < 6 &&
          <button className={styles.buttonNotes} onClick={handleAddNote}>
            Agregar Punto
          </button>}
          <div 
          style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
          <button onClick={handleOpenMoreModal}
          className={styles.button}>Aceptar</button>
          </div>
          </div>
      </Modal>}
      {optionalSlidesModal &&
      <Modal title={"Seleccionar Año Kurios"} minWidth={isDesktop ? "50%" : "90%"}
      modalOpen={setOptionalSlidesModal}>
        <div style={{height:'100%', overflowY:'scroll', width:'100%'}}>
        <p style={{margin: '5px', padding: '10px', textAlign: 'center'}}>
          Seleccione las láminas que se deben de incluir en esta presentación
        </p>
        <FormGroup sx={{marginLeft: '20px'}}>
          {optionalSlides?.map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={option.valor}
                  onChange={() => handleCheckboxChange(index)}
                  sx={{ transform: 'scale(1.5)' }} // Ajusta el tamaño del checkbox
                />
              }
              label={option.name}
              sx={{ marginBottom: '0.5rem' }}
            />
          ))}
        </FormGroup>
          <div 
          style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
          <button onClick={handleOpenLinkModal}
          className={styles.button}>Aceptar</button>
          </div>
          </div>
      </Modal>}
      {openLinkDashboard &&
      <Modal title={"Link Aprovechamiento alianza"} modalOpen={setOpenLinkDashboard}>
         <div style={{height:'100%', overflowY:'scroll'}}>
         <input 
          onChange={(e) => setLinkDashboard(e.target.value)}
          placeholder="Ingrese el link"
          type='text'
          style={{flex: 1, marginRight: '1rem', borderRadius: '10px', 
          padding: '5px', marginTop: '5px'}}
          />
          <div 
          style={{display: 'flex', justifyContent: 'center', marginTop: '2rem'}}>
          <button onClick={handleGeneratePPT}
          className={styles.button}>Aceptar</button>
          </div>
          {loading &&
          <p>Cargando...</p>}

         </div>

      </Modal>
      }
      {loading && (
        <LoadingComponent />
      )}
    </div>
  )
}

export default InstitutionResumePPT

