import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';
import linkifyHtml from 'linkify-html';
import Modal from '../Modal';
import extractVideoId from '../../utils/extractVideoId';
import YoutubeEmbed from '../../utils/YoutubePreview';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAnnouncements } from '../../context/AnnouncementsContext';

const Cont = styled('div')({
    fontStyle: 'italic',
  fontSize: '14px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'var(--background-gray)',
  padding: '5px 15px',
  borderRadius: 'var(--regular-border-radius)',
  height: '60px',
  alignItems: 'center',
});

function ModalComunicado({selectedAnnouncement, setSelectedAnnouncement}) {
    const { userAnnouncements, markAnnouncementAsRead } = useAnnouncements();
    const [announcementText, setAnnouncementText] = useState(null);
    const [modalOpen, setModalOpen] = useState(true);
    const [isReadChecked, setIsReadChecked] = useState(false);

    useEffect(() => {
        if (selectedAnnouncement?.data?.content) {
          setAnnouncementText(selectedAnnouncement.data.content.replace(/^"|"$/g, ""));
        }
      }, [selectedAnnouncement,announcementText]);
    
    
    const handleAcceptAnnouncement = () => {
      markAnnouncementAsRead(selectedAnnouncement.id);
      setModalOpen(false);
      setSelectedAnnouncement(null);
    };


  return (
    <Box>
        <h1 style={{margin: '10px 0'}}>Anuncios</h1>
        <Box sx={{ maxHeight: '150px', overflowY: 'auto' }}>
        <Stack direction={"column"}
          spacing={1}>
            {modalOpen && (
                <Modal
                  title={selectedAnnouncement?.data?.title}
                  action={"Aceptar"}
                  modalOpen={setModalOpen}
                  acceptModal={handleAcceptAnnouncement}
                  type={"announcement"}
                  disableAction={!isReadChecked}
                > 
                    <div
                        style={{padding: "5px 15px"}}
                        dangerouslySetInnerHTML={{
                          __html: linkifyHtml(announcementText ?? "", {
                            attributes: {
                              target: "_blank",
                            },
                          }),
                        }}
                    />
                     
                    {selectedAnnouncement?.data?.url && (
                        <>
                          {selectedAnnouncement?.data?.url?.includes(
                            "youtu"
                          ) ? (
                            <div style={{ marginTop: "20px" }}>
                              <YoutubeEmbed
                                url={selectedAnnouncement?.data?.url}
                                embedId={extractVideoId(
                                  selectedAnnouncement?.data?.url
                                )}
                              />
                            </div>
                          ) : (
                            <div style={{ padding: "20px 15px" }}>
                              <p style={{ fontWeight: "bold" }}>
                                Accede al enlace aquí:{" "}
                              </p>
                              <Link
                                target="_blank" rel="noreferrer"
                                to={selectedAnnouncement?.data?.url}
                              >
                                {selectedAnnouncement?.data?.url}
                              </Link>
                            </div>
                          )}
                        </>
                    )}
                    <FormControlLabel
                        control={
                        <Checkbox
                            checked={isReadChecked}
                            onChange={(e) => setIsReadChecked(e.target.checked)}
                        />
                        }
                        label="He leído y comprendido el comunicado"
                        style={{ marginTop: "10px" }}
                    />
                  </Modal>
                )}           
        </Stack>
        </Box>
    </Box>
  )
}

export default ModalComunicado
