import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import {Grid, Link, Button, Box} from "@mui/material";
import styles from "./CapacitacionView.module.css";
const InstruccionsAndOther = ({capacitacion}) => {
    return (
        <Grid container direction={"row"} columnGap={1} rowGap={1}>
            <Grid item  md = {8.9} xs={8.85} sm={8.85} className={styles.stylegrid}>
                <div className={styles.description}>
                    <p>
                        En el marco de las formaciones continuas, se requiere completar la actividad 
                        asignada y entregar un archivo con la documentación correspondiente. 
                        A continuación, se detallan los pasos a seguir:
                    </p><br/>
                    <ul>
                        <p style={{ marginLeft: "12px" }}><b>A) Realización de la Actividad:</b> 
                        Complete todas las tareas y ejercicios especificados en la actividad de formación.
                         Asegúrese de seguir todas las instrucciones proporcionadas para cada paso de la 
                         actividad.</p><br/>
                        <p style={{ marginLeft: "12px" }}><b>B) Entrega del Archivo:</b> Suba el archivo
                         preparado a la plataforma en la sección de entregas (Click para subir archivo). 
                         Recuerde seguir las instrucciones proporcionadas en la formacion para la carga del 
                         archivo.</p><br/>
                        <p style={{ marginLeft: "12px" }}><b>C) Completar la autoevaluación:</b> Después 
                        de entregar el archivo, acceda al formulario proporcionado en la plataforma en la 
                        sección autoevaluación. Complete el formulario con la información requerida.</p><br/>
                    </ul>
                    <p style={{marginLeft: '30px'}}>Para responder el formulario, tenga en cuanta
                         la siguiente escala del 1 al 5:</p>
                    <br/>
                    <div style={{marginLeft: '30px'}}>
                        <p style={{ marginLeft: "10px" }}><b>1:</b> No lo logré en absoluto. No pude completar 
                        las tareas relacionadas con este indicador o no cumplí con los criterios establecidos.</p><br/>
                        <p style={{ marginLeft: "10px" }}><b>2:</b> Lo logré en parte. Tuve dificultades para 
                        cumplir con algunos de los criterios y requiero más práctica o apoyo.</p><br/>
                        <p style={{ marginLeft: "10px" }}><b>3:</b> Lo logré de manera satisfactoria. Cumplí 
                        con la mayoría de los criterios y demostré un nivel básico de competencia.</p><br/>
                        <p style={{ marginLeft: "10px" }}><b>4:</b> Lo logré muy bien. Superé las expectativas
                         y demostré un alto nivel de competencia en este indicador.</p><br/>
                        <p style={{ marginLeft: "10px" }}><b>5:</b> Lo dominé por completo. Logré todos los 
                        objetivos y demostré un dominio excepcional de este indicador.</p><br/>

                    </div>
                </div>
                {capacitacion?.activity && (
                    <>
                    <div className={styles.task_info}>
                        <a target="_blank" rel="noreferrer" href={capacitacion.activity}
                        style={{textDecoration: 'none', fontSize: '16px', color: 'var(--main-blue)'}} >
                        <i class="fad fa-book-reader" style={{fontSize: '18px', marginRight: '5px', 
                            color: 'var(--main-blue)'}}></i> Guía de actividad</a>
                    </div>
                    </>
                )}
            </Grid>
            <Grid item  md = {3} xs={3} sm={3} className={styles.stylegrid}>
            <Grid container direction={"column"} columnGap={1} rowGap={1}>                 
              {capacitacion?.resources?.map((resource, index) => ( 
                <Grid item xs={2.93} md={2.98} key={index}>
                  {resource.type === 'GUIDE' && ( 
                    <Link href={resource.url} 
                      target="_blank" rel="noopener noreferrer"> 
                      <Button variant="outlined" sx={{ borderRadius: '10px', width: 
                       '100%', height: '100%', backgroundColor: '#fff', fontSize: '11px' }}
                        startIcon={<i className="fa fa-book"></i>} > 
                          {resource.name} 
                       </Button> 
                     </Link> 
                  )} 
                  {resource.type === 'VIDEO' && (
                    <Link href={resource.url} target="_blank" rel="noopener noreferrer">
                      <Button
                        variant="outlined"
                        sx={{borderRadius: '10px', 
                        width:'100%', 
                        height:'100%', backgroundColor: '#fff', fontSize:'11px'}}
                        startIcon={<i className="fab fa-youtube"></i>}
                        >
                          {resource.name}
                      </Button>
                    </Link>
                  )}
                  {resource.type === 'PROGRAM' && ( 
                    <Link href={resource.url} 
                      target="_blank" rel="noopener noreferrer"> 
                      <Button variant="outlined" sx={{ borderRadius: '10px', width: 
                        '100%', height: '100%', backgroundColor: '#fff', fontSize: '11px' }}
                        startIcon={<i className="fas fa-books"></i>} > 
                        {resource.name} 
                      </Button> 
                    </Link> 
                  )} 

                  {resource.type === 'SCREENSHOT' && ( 
                    <Link href={resource.url} 
                      target="_blank" rel="noopener noreferrer"> 
                      <Button variant="outlined" sx={{ borderRadius: '10px', width: 
                        '100%', height: '100%', backgroundColor: '#fff', fontSize: '11px' }}
                        startIcon={<i className="far fa-image"></i>} > 
                          {resource.name} 
                      </Button> 
                    </Link> 
                  )} 
                  {resource.type === 'RESOURCE' && (
                    <Link href={resource.url} target="_blank" rel="noopener noreferrer">
                      <Button
                        variant="outlined"
                        sx={{borderRadius: '10px', 
                        width:'100%', 
                        height:'100%', backgroundColor: '#fff', fontSize:'11px'}}
                        startIcon={<i class="fad fa-chalkboard"></i>}
                      >
                        {resource.name}
                      </Button>
                    </Link>
                  )}
                  </Grid> 
                ))}
                  </Grid>
          </Grid>
        </Grid>
    )
}
export default InstruccionsAndOther;