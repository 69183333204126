import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import {Grid, Link, Button, Box} from "@mui/material";
import * as Yup from "yup";
import { MyTextInput, MySubmitButton, MyTextarea } from "../../FormFields";
import { CapacitacionesContext } from "../../../context/CapacitacionesContext";
import YoutubeEmbed from "../../../utils/YoutubePreview";
import extractVideoId from "../../../utils/extractVideoId";
import styles from "./CapacitacionView.module.css";
import Container from "../../Container";
import { CAPACITACION_ENDPOINT, USER_QUIZ_ENDPOINT } from "../../../utils/constants";
import { wordCapitalize } from "../../../utils/capitalize";
import { get } from "../../../utils/http";
import Modal from "../../Modal";
import { dateFormatter } from "../../../utils/dateFormatter";
import { handleDownload } from "../../../utils/downloadFile";
import { handleDownloadExcel } from "../../../utils/downloadToExcel";
import InstruccionsAndOther from "./InstruccionsAndOther";

function CapacitacionView() {
  const { reviewTask } = useContext(CapacitacionesContext);
  const [openModal, setOpenModal] = useState(false);
  const [capacitacion, setCapacitacion] = useState([]);
  const [taskId, setTaskId] = useState(null);
  const [quicesAnswers, setQuicesAnswers] = useState([])
  const [selectedQuiz, setSelectedQuiz] = useState([])
  const [openAnswers, setOpenAnswers] = useState(false)
  const [exportData, setExportData] = useState([])
  const [filterSearch, setFilterSearch] = useState("")

  const { capacitacionId } = useParams();

  const navigate = useNavigate();

  const getCapacitacion = async () => {
    const url = `${CAPACITACION_ENDPOINT}${capacitacionId}/`;
    const response = await get({ url });
    setCapacitacion(response.data);
  };

  useEffect(() => {
    getCapacitacion();
  }, [capacitacionId]);

  const getQuizAnswers = async () => {
    const url = `${USER_QUIZ_ENDPOINT}?quiz=${parseInt(capacitacion?.quiz?.id)}`;
    const response = await get({ url });
    setQuicesAnswers(response.data);
  };

  const getUserQuizAnswers = async (user) => {
    const url = `${USER_QUIZ_ENDPOINT}?quiz=${parseInt(capacitacion?.quiz?.id)}&user=${user} `;
    const response = await get({ url });
    setSelectedQuiz(response.data);
    setOpenAnswers(true)
  };

  useEffect(() => {
    if(capacitacion?.quiz){
      getQuizAnswers();
    }
  }, [capacitacion]);

  const handleOpenmodal = (task) => {
    setTaskId(task.id);
    setOpenModal(true);
  };

  const handleReviewTask = (values) => {
    reviewTask({ taskId, values });
    getCapacitacion();
    setOpenModal(false);
  };

  const teachersWithTasks = capacitacion?.teachers?.filter((teacher) => {
    return capacitacion?.tasks?.some((task) => task.teacher === teacher.id);
  });

  const teachersWithoutTasks = capacitacion?.teachers?.filter((teacher) => {
    return !capacitacion?.tasks.some((task) => task.teacher === teacher.id);
  });
  const teachersWithQuiz = capacitacion?.teachers?.filter((teacher) => {
    return quicesAnswers?.users_quiz?.some((task) => task.user.id === teacher.id);
  });

  const teachersWithoutQuiz = capacitacion?.teachers?.filter((teacher) => {
    return !quicesAnswers?.users_quiz?.some((task) => task.user.id === teacher.id);
  });

  const searchValue = String(filterSearch || "").toLowerCase().trim().replace(/\s+/g, " ");
  const renderTasks = (teachers) => {
    teachers?.sort((a, b) => {
      const fullNameA = `${a.first_name} ${a.last_name}`;
      const fullNameB = `${b.first_name} ${b.last_name}`;
      return fullNameA.localeCompare(fullNameB);
    })
    return teachers?.map((teacher) => {
      const task = capacitacion?.tasks?.find(
        (task) => task.teacher === teacher.id
      );
      if (task) {
        return (
          <div className={styles.task}>
            <p style={{ fontSize: "14px", width: "20%" }}>
              {teacher.first_name} {teacher.last_name}
            </p>
            <div
              style={{ width: "20%", display: "flex", flexDirection: "column" }}
            >
              <p style={{ fontSize: "11px", textAlign: "center" }}>
                Fecha de Entrega
              </p>
              <p
                style={{
                  fontSize: "13px",
                  textAlign: "center",
                  paddingTop: "5px",
                }}
              >
                {dateFormatter(task.date)}
              </p>
            </div>
            <div
              style={{ width: "20%", display: "flex", flexDirection: "column" }}
            >
              <p style={{ fontSize: "11px", textAlign: "center" }}>Nota</p>
              <p
                style={{
                  fontSize: "13px",
                  textAlign: "center",
                  paddingTop: "5px",
                }}
              >
                {task.grade}
              </p>
            </div>
            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p
                className={styles.comment}
                onClick={() => handleDownload(task.file)}
              >
                Descargar Entrega
              </p>
            </div>
            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <p
                className={styles.review}
                onClick={() => handleOpenmodal(task)}
              >
                Corregir
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <div className={styles.task}>
            <p style={{ fontSize: "14px", width: "20%" }}>
              {teacher.first_name} {teacher.last_name}
            </p>
            <div
              style={{ width: "20%", display: "flex", flexDirection: "column" }}
            >
              <p style={{ fontSize: "11px", textAlign: "center" }}>
                Fecha de Entrega
              </p>
              <p
                style={{
                  fontSize: "13px",
                  textAlign: "center",
                  paddingTop: "5px",
                }}
              >
                -
              </p>
            </div>
            <div
              style={{ width: "20%", display: "flex", flexDirection: "column" }}
            >
              <p style={{ fontSize: "11px", textAlign: "center" }}>Nota</p>
              <p
                style={{
                  fontSize: "13px",
                  textAlign: "center",
                  paddingTop: "5px",
                }}
              >
                -
              </p>
            </div>
            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "center",
              }}
            ></div>
            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <p className={styles.no_review}>Sin entregar</p>
            </div>
          </div>
        );
      }
    });
  };

  useEffect(() => {
    let data = []
  
    capacitacion?.teachers?.forEach((teacher) => {
      const quiz = quicesAnswers?.users_quiz?.find(
        (quiz) => quiz?.user?.id === teacher?.id
      );
      const task = capacitacion?.tasks?.find(
        (task) => task?.teacher === teacher.id
      );
      const dat = {
        Docente: `${teacher?.first_name} ${teacher?.last_name}`,
        Entrego: `${quiz ? "Si" : (task ? "Si" : "No")}`,
        Nota: `${quiz ? quiz?.grade : (task ? (task?.reviewed ? task?.grade : "Sin corregir") : "N/A")}`,
      }
      data?.push(dat)
    })

    data?.sort((a, b) => {
      if (a?.Entrego === 'Si' && b?.Entrego !== 'Si') {
        return -1;
      }
      if (a?.Entrego !== 'Si' && b?.Entrego === 'Si') {
        return 1;
      }
      return 0;
    });
    setExportData(data)
  }, [capacitacion, quicesAnswers]);

  const renderQuices = (teachers) => {
    teachers?.sort((a, b) => {
      const fullNameA = `${a.first_name} ${a.last_name}`;
      const fullNameB = `${b.first_name} ${b.last_name}`;
      return fullNameA.localeCompare(fullNameB);
    })
    return teachers?.map((teacher) => {
      const quiz = quicesAnswers?.users_quiz?.find(
        (quiz) => quiz.user.id === teacher.id
      );
      if (quiz) {
        return (
          <div className={styles.task}>
            <p style={{ fontSize: "14px", width: "20%" }}>
              {teacher.first_name} {teacher.last_name}
            </p>
            <div
              style={{ width: "20%", display: "flex", flexDirection: "column" }}
            >
              <p style={{ fontSize: "11px", textAlign: "center" }}>Nota</p>
              <p
                style={{
                  fontSize: "13px",
                  textAlign: "center",
                  paddingTop: "5px",
                }}
              >
                {`${quiz.grade}/${capacitacion?.quiz?.estimated_grade}`}
              </p>
            </div>
            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p
                className={styles.submitted}
              >
                Entregado
              </p>
            </div>
            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "flex-end",
              }}
              
            >
              <p
                className={styles.review}

                onClick={() => getUserQuizAnswers(teacher.id)}
              >
                Ver Respuestas
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <div className={styles.task}>
            <p style={{ fontSize: "14px", width: "20%" }}>
              {teacher.first_name} {teacher.last_name}
            </p>
            <div
              style={{ width: "20%", display: "flex", flexDirection: "column" }}
            >
              <p style={{ fontSize: "11px", textAlign: "center" }}>Nota</p>
              <p
                style={{
                  fontSize: "13px",
                  textAlign: "center",
                  paddingTop: "5px",
                }}
              >
                -
              </p>
            </div>
            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "center",
              }}
            ></div>
            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <p className={styles.no_review}>Sin completar</p>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <Container>
      <div className={styles.container}>
        <i
          className="fal fa-arrow-left"
          style={{
            fontSize: "12px",
            cursor: "pointer",
            marginBottom: "10px",
          }}
          onClick={() => navigate(-1)}
        ></i>
        <div>
          <p className={styles.title}>
            {wordCapitalize(capacitacion?.title || "")}
          </p>
          <button
            onClick={() =>
              handleDownloadExcel(exportData, wordCapitalize(capacitacion?.title))
            }
            className={styles.download}
          >
            Descargar Tabla
          </button>
        </div> 
        <InstruccionsAndOther capacitacion={capacitacion} />
        {capacitacion.task_info &&
        <div>
          <div className={styles.options}>
            {capacitacion?.task_info && 
            <p className={styles.title2}>
              Entregas</p>}
            {capacitacion?.quiz &&
            <p className={styles.title2}
            >
              Quices</p>}
          </div>
          <div className={styles["search-bar2"]}>
            <input
              id="search"
              type="text"
              placeholder="Buscar por nombre"
              aria-label="Search Input"
              value={filterSearch}
              onChange={(e) => setFilterSearch(e.target.value)}
              className={styles.filter_text}
            />
        </div>
          {(capacitacion?.task_info) &&
            <>
              {renderTasks(
                teachersWithTasks.filter((teacher) =>
                  (`${teacher?.first_name || ""} 
                    ${teacher?.last_name || ""}`).toLowerCase().
                    replace(/\s+/g, " ").includes(searchValue)
                )
              )}
              {renderTasks(
                teachersWithoutTasks.filter((teacher) =>
                  (`${teacher?.first_name || ""}
                     ${teacher?.last_name || ""}`).toLowerCase().
                     replace(/\s+/g, " ").includes(searchValue)
                )
              )}
            </>
          }
          {(capacitacion?.quiz) &&
            <>
             {renderQuices(
              teachersWithQuiz.filter((teacher) =>
                (`${teacher?.first_name || ""} 
                  ${teacher?.last_name || ""}`).toLowerCase().
                  replace(/\s+/g, " ").includes(searchValue)
              )
            )}
            {renderQuices(
              teachersWithoutQuiz.filter((teacher) =>
                (`${teacher?.first_name || ""} 
                  ${teacher?.last_name || ""}`).toLowerCase().
                  replace(/\s+/g, " ").includes(searchValue)
              )
            )}
            </>
          }
        </div>
        }
      </div>
      {openModal && (
        <Modal title={"Corregir Entrega"} modalOpen={setOpenModal}>
          <div>
            <Formik
              initialValues={{
                grade: 0,
                comment: "",
                reviewed: true,
              }}
              validationSchema={Yup.object({
                comment: Yup.string(),
                grade: Yup.number().required("Campo requerido"),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => {
                  handleReviewTask(values);
                  setSubmitting(false);
                }, 400);
                resetForm();
              }}
            >
              <Form className={styles["form__fields"]}>
                <MyTextInput
                  label="Nota"
                  name="grade"
                  type="number"
                  placeholder="Nota"
                  icon="fa fa-user"
                />
                <MyTextarea label="Comentario" name="comment" />
                <div
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    marginTop: "20px",
                  }}
                >
                  <MySubmitButton name="Corregir" type="submit" />
                </div>
              </Form>
            </Formik>
          </div>
        </Modal>
      )}
      {openAnswers && (
        <Modal title={"Respuestas"} modalOpen={setOpenAnswers}>
          <div style={{height: '100%', overflowY: 'scroll'}}>
          {selectedQuiz?.answers?.map((quiz) => {
            const answerAux = quiz?.question?.options?.filter((item) => item?.correct_answer)
            return(
              <div>
                <p style={{fontWeight: 'bold'}}>{quiz?.question?.content}</p>
                <div style={{display: 'flex', gap: '10px'}}>
                
                {(quiz?.question?.options_type === 3 || 
                  quiz?.question?.options_type === 4 || 
                  quiz?.question?.options_type === 5) ? (
                    <p>{`Respuesta: ${quiz?.answer?.answer}`}</p>
                ) : (
                    <>
                
                    <p>{`Respuesta: ${quiz?.answer_option?.description}`}</p>
                    {(quiz?.answer_option?.correct_answer ? (
                      <i className="fas fa-check" style={{ color: 'var(--sec-green)' }}></i>
                    ) : (
                      <>
                      <i className="fas fa-times" style={{ color: 'var(--sec-red)' }}></i>
                      {!quiz?.answer_option?.correct_answer &&
                      <p>{`Respuesta Correcta: ${answerAux[0]?.description}`}</p>}
                      </>
                    ))}
                    </>
                    
                )}

                </div>
              </div>
            )
          })}
          </div>
        </Modal>
      )}
    </Container>
  );
}

export default CapacitacionView;
