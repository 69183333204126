// Habilidades docentes que se mostrarán en la sección de análisis de acompañamiento
const skills =['Conocimiento del contenido del programa', 
    'Hace uso de la planificación de Kurios',
     'Cumple con el objetivo de aprendizaje de la clase',
    'Se evidencia el inicio, desarrollo y cierre de la clase',
    'Indaga sobre los conocimientos previos de los estudiantes',
      'Hace uso de preguntas orientadas al tema planteado',
    'Atiende a las dudas de los estudiantes', 
    'Propone ejemplos que vinculan el contenido de la clase con el entorno',
    'Porcentaje de avances de las clases',
  ]

  const skillsTekman = ['Hace uso de la planificación de Tekman',
    'Conocimiento del contenido del programa',
    'Tiene dominio del grupo',
    'Propone ejemplos que vinculan el contenido de la clase con el entorno',
    'Se evidencia que el docente prepara previamente la clase',
    'Cumple con el objetivo del programa',
  ];
  
  const yearStageImages = {
    'Año 1': {
      'Preescolar': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/20.png',
      ],
      'Primaria': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/21.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/22.png',
      ],
      'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/23.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/24.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/25.png'
      ]
    },
    'Año 2': {
      'Preescolar': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/27.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/28.png',
      ],
      'Primaria': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/29.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/30.png'
      ],
      'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/31.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/32.png',
      ]
    },
    'Año 3': {
      'Preescolar': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/34.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/35.png',
      ],
      'Primaria': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/36.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/37.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/38.png',
      ],
      'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/39.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/40.png',
      ]
    },
    'Año 4+': {
      'Preescolar': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/42.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/43.png',
      ],
      'Primaria': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/44.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/45.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/48.png',
      ],
      'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/46.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/47.png',
      ]
    },
    'Digital Skills I': { 
        'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/programasAdicionales.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/54.png'
        ]
    },
    'Digital Skills II': { 
        'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/programasAdicionales.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/55.png'
        ]
    },
    'Marketplace': { 
        'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/programasAdicionales.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/56.png'
        ]
    },
    'Digital Skills I y II': { 
        'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/programasAdicionales.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/52.png'
        ]
    },
    'Digital Skills I - Marketplace': { 
        'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/programasAdicionales.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/51.png'
        ]
    },
    'Digital Skills I y II - Marketplace': { 
        'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/programasAdicionales.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/50.png'
        ]
    },
    'Digital Skills II - Marketplace': { 
        'Bachillerato': [
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/programasAdicionales.png',
        'https://kuriosedu.s3.us-east-2.amazonaws.com/media/presentaciones/53.png'
        ]
    },
  };

  
export { skills, yearStageImages, skillsTekman };